import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Tooltip } from '@material-ui/core'
import { Assessment, ToggleOff, ToggleOn, Edit } from '@material-ui/icons'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls'
import Table, { buttonsStyle } from '../../../components/tables/table'
import { getSurveys, changeStatusSurvey } from '../../../../api/survey'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { getNonEmpty } from '../../../../utils/helpers'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'

const getData = (surveys) =>
	surveys.map((survey) => ({
		id: survey._id,
		name: getNonEmpty(survey.name),
		active: survey.active,
		numQuestions: survey.questions?.length,
		entries: `${survey.entries}/${survey.maxEntries}`,
	}))

export function SurveysPage() {
	const [data, setData] = useState([])
	const [selectedSurvey, setSelectedSurvey] = useState(null)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null)
	const [refresh, setRefresh] = useState(false)
	const history = useHistory()
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell)
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-survey/' + cell)}>
						<Edit />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title={elem?.active ? 'Desactivar' : 'Activar'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedSurvey(elem)
									setOpenConfirmDialog(3)
								}}>
								{elem?.active ? (
									<ToggleOn />
								) : (
									<ToggleOff style={{ color: 'red' }} />
								)}
							</Button>
						</Tooltip>
						{elem.entries.split('/')[0] !== '0' && (
							<Tooltip title={'Respuestas'}>
								<Button
									style={buttonsStyle}
									size='small'
									onClick={() =>
										history.push('/view-survey/' + cell)
									}>
									<Assessment />
								</Button>
							</Tooltip>
						)}
					</>
				)}
			</>
		)
	}

	function numberFormatter(cell) {
		const [entries, maxEntries] = cell.split('/')
		return (
			<Tooltip
				title={`La encuesta se cerrará cuando se reciban ${maxEntries -
					entries} respuestas`}>
				<b>{cell}</b>
			</Tooltip>
		)
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{
			dataField: 'numQuestions',
			text: 'Preguntas',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},
		{
			dataField: 'entries',
			text: 'Entradas/Límite',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: numberFormatter,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	]

	useEffect(() => {
		getSurveys()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data))
					setRefresh(false)
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get surveys.' })
			)
	}, [refresh])

	return (
		<>
			<Card>
				<CardHeader title='Listado de encuestas'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-survey')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							selectedSurvey?.active ? 'desactivar' : 'activar'
						} esta encuesta?`}
						open={openConfirmDialog === 3}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusSurvey(
								selectedSurvey.id,
								!selectedSurvey?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedSurvey?.active
													? '¡Desactivada!'
													: '¡Activada!'
											}`,
											customMessage: `Encuesta ${
												selectedSurvey?.active
													? 'desactivada'
													: 'activada'
											} con éxito`,
										})
										setRefresh(true)
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedSurvey?.active
												? 'disable'
												: 'enable'
										} survey.`,
									})
								)
						}}
					/>
				</CardBody>
			</Card>
		</>
	)
}
